import React, { useEffect, useState } from "react";

import { documentService } from "../../services/documentService";
import signLogo from "../../images/sign.png";
import { Error } from "../Error/error";
import Loader from "../Loader/loader";
import Modal from "react-modal";
import Locks from "../../images/locks.png";
import loginImage from "../../images/login.png";
import eye from "../../images/eye.svg";
import eyeSlash from "../../images/eye-slash.svg";
import newSignAppLogo from "../../images/sign vector_1.png"

import Footer from "../../components/Footer/Footer.jsx";

const loginModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 60,
    padding: 0,
    // height: 550,
    // backgroundColor: 'rgb(33, 25, 25/ 75%)'
    backgroundColor: "#E9E9E9",
  },
  overlay: {
    background: "rgba(0,0,0,0.7)",
  },
};

const PasswordReset = ({ match }) => {
  const [token, setToken] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [ticket, setTicket] = useState(false);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    console.log(match);
    setToken(match.params.token);
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, [match]);

  const handleClose = () => {
    setError(false);
  };

  const handleShowChange = (event) => {
    setShow(!show);
  };

  const handleTicketChange = (event) => {
    setTicket(!ticket);
  };

  const handlePasswordInput = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordInput = (event) => {
    setConfirmPassword(event.target.value);
  };

  const onSubmit = () => {
    if (password?.length == 0) {
      setMessage(
        "Passwords can't be blank. Choose a secure password to protect your account."
      );
      setError(true);
    }
    if (password?.length < 6) {
      setMessage(
        "Strong passwords keep your account safe. Choose a password with at least 6 characters."
      );
      setError(true);
    }

    if (password !== confirmPassword) {
      setMessage(
        "The passwords you entered don't match. Please re-type and try again."
      );
      setError(true);
      return;
    }
    if (!isOnline) {
      // alert("Check internet connection");
      setMessage(
        "You seem to be offline. Connect to Wi-Fi or mobile data and try again."
      );
    }

    const body = {
      token: token,
      password,
    };

    // console.log(body);
    setIsLoading(true);
    let response;

    documentService
      .changePassword(body)
      .then((res) => {
        // console.log('Response is ', res)
        response = res;
        if (response.message === "Success") {
          setChangeSuccess(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setError(true);
          setMessage("Failed to change password. Please try again.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const Header = () => {
    return (
      <header
        className=" mb-0  bg-white"
        style={{
          background: "white",
          color: "black",
          padding: "25px",
          borderBottom: "1px solid #c7c7c7bd",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <img
              src={newSignAppLogo}
              style={{
                width: "41px",
                marginRight: "10px",
              }}
            />
          </div>
          <h1
            style={{
              fontSize: "25px",
              fontWeight: "bolder",
              position: "relative",
              top: "1px",
            }}
          >
            Sign Documents: SignPe
          </h1>
        </div>
      </header>
    );
  };

  // const Footer = () => {
  //   return (
  //     <footer style={{
  //       background: "white",
  //       color: "black",
  //       padding: "25px",
  //       position: "fixed",
  //       left: "0",
  //       bottom: "0",
  //       width: "100%",
  //       borderTop:'1px solid rgb(181 181 181)'
  //     }}>
  //       <p>Powered by <b>SignPe</b></p>
  //       <div style={{display:"flex"}}>
  //        <p style={{paddingRight:"10px"}}> Contact Us</p>
  //        <p style={{paddingRight:"10px"}} > Terms of Us</p>
  //        <p>Privacy</p>
  //       </div>

  //       <p>Copyright &copy; SignPe. All rights reserved.</p>
  //     </footer>
  //   );
  // };

  return (
    <div>
      {!changeSuccess ? (
        <div className="min-h-screen flex flex-col items-center justify-center sm:py-12">
          <div className="p-10 xs:p-0 mx-auto xl:w-full xl:max-w-xl">
            <div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200 p-8">
              <div className="plr-24 space-y-8">
                <div>
                  <img
                    className="mx-auto h-12 w-auto"
                    src={newSignAppLogo}
                    alt="Workflow"
                  />
                  <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Change Password
                  </h2>
                </div>

                {/* <input type="hidden" name="remember" defaultValue="true" /> */}
                <div className="mt-8 space-y-6">
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div className="mb-1">
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <label htmlFor="password" className="sr-only">
                          Password
                        </label>
                        <input
                          id="password"
                          name="password"
                          style={{ paddingRight: "39px !important" }}
                          type={show ? "text" : "password"}
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                          placeholder="Password"
                          onChange={handlePasswordInput}
                        />
                        <img
                          src={show ? eyeSlash : eye}
                          width={20}
                          height={20}
                          alt="show password"
                          style={{ position: "absolute", top: 10, right: 15 }}
                          onClick={handleShowChange}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "15px",
                        position: "relative",
                      }}
                    >
                      <label htmlFor="confirm_password" className="sr-only">
                        confirmPassword
                      </label>
                      <input
                        id="confirm_password"
                        name="confirm_password"
                        style={{ paddingRight: "39px !important" }}
                        type={ticket ? "text" : "password"}
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Confirm Password"
                        onChange={handleConfirmPasswordInput}
                      />
                      <img
                        src={ticket ? eyeSlash : eye}
                        width={20}
                        height={20}
                        alt="show password"
                        style={{ position: "absolute", top: 10, right: 15 }}
                        onClick={handleTicketChange}
                      />
                    </div>
                  </div>
                  {/* <div className="flex items-center justify-between">*/}

                  {/*    <div className="text-sm">*/}
                  {/*        <a*/}
                  {/*            href="#"*/}
                  {/*            className="font-medium text-indigo-600 hover:text-indigo-500"*/}
                  {/*            // onClick={openPasswordForgotPage}*/}
                  {/*        >*/}
                  {/*            Forgot your password?*/}
                  {/*        </a>*/}
                  {/*    </div>*/}
                  {/*</div> */}
                  <div>
                    <button
                      style={{ backgroundColor: "#378cf2" }}
                      type="submit"
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={onSubmit}
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <svg
                          className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          {/* <path
                            fillRule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clipRule="evenodd"
                          /> */}
                        </svg>
                        <img src={Locks} height="18px" width="18px"></img>
                      </span>
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Error
            show={error}
            message={message}
            duration={4000}
            position={{ vertical: "top", horizontal: "right" }}
            onClose={handleClose}
          />
        </div>
      ) : (
        <div>
          <Header />
          <div
            style={{
              height: "calc(100vh - 250px)",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              background: "white",
            }}
          >
            <div style={{ padding: "0px 50px " }}>
              <div>
                Your password has been successfully reset. You may now utilize
                the new password to access the application.
              </div>
              <div style={{ textAlign: "center" }}>
                <a
                  href="https://signpeapp.page.link/jdF1"
                  // href=" https://signpeapp.web.app/"
                  // href="https://signpe-web.web.app/"

                  style={{
                    backgroundColor: "#378cf2",
                    width: "auto",
                    display: "inline-block",
                    marginTop: "20px",
                    textDecoration: "none",
                  }}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  // onClick={submit}
                >
                  Go to Sign Documents App
                </a>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
      <Error
        show={error}
        message={message}
        duration={4000}
        position={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
      />
      <Modal
        isOpen={isLoading}
        style={loginModalStyle}
        onRequestClose={() => {
          setIsLoading(false);
        }}
      >
        <Loader />
      </Modal>
    </div>
  );
};

export default PasswordReset;
